/* Home page layout */
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 100px 0; /* Adjust padding to account for fixed header */
  }
  
  .home-text {
    margin-bottom: 30px;
  }
  
  .home-image {
    max-width: 500px;
  }
  
  h1 {
    margin-bottom: 10px;
    font-size: 36px;
  }
  
  p {
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .home-text {
      margin-bottom: 15px;
    }
  }
  