form {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
}

label {
    font-size: 1.2em;
    margin-bottom: 0.5em;
}

input[type="text"], input[type="email"], textarea {
    margin-bottom: 1em;
    padding: .25em;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    width: 100%;
}

textarea {
    height: 150px;
}

button {
    padding: 0.5em 1em;
    color: white;
    background-color: #005cbf;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #004c9e;
}
