.home-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
}
  
.home-text {
    position: absolute;
    width:70%;
    bottom: 40%;
    text-align: center;
    font-size: 2.5rem;
    color: aliceblue;
}
  
.home-text h1 {
    font-size: 4rem;
    text-align: center;

}

.home-image {
    position: absolute;    /* Position it absolutely within its parent */
    right: 0;               /* Align to the left edge */
    top: 50%;              /* Start at the middle of the page vertically */
    transform: translateY(50%); /* Shift it up by half its own height to center */
    max-width: 100%;       /* Ensure it does not exceed the width of its container */
    height: auto;          /* Maintain aspect ratio */
}



.about-container h1 {
    text-align: center;
    color: aliceblue;
    padding: 20px;
}

.about-container {
    padding-top: 20px;
}

.about-container p {
    padding-top: 100px;
    text-align: center; /* Centers the text horizontally */
    width: 50%; /* You can adjust this */
    margin: 0 auto; /* Centers the block itself */
    line-height: 1.6; /* Adjust line spacing */
    padding: 20px; /* Adds some space around the text */
    box-sizing: border-box; /* Makes sure the padding is included in the width */
    color: aliceblue;
}

.about-container .social-icons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-container .social-icons a {
    margin-left: 10px; /* Adjust as needed */
    margin-right: 10px; /* Adjust as needed */
    color: aliceblue;
}

.about-container .social-icons a:hover{
    color: rgb(217, 41, 21);
}

.about-image {
    margin-top: 0;
    padding-top: 0;
    object-fit: cover;
    overflow-x: hidden;
}

p {
    margin: 0;    
}

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 20px;
}

.project-card {
    display: flex;
    background: rgb(229, 229, 229);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(248, 248, 248, 0.899);
    transition: all 0.2s ease-in-out;
    text-align: center;
    min-height: 100;
}

.project-card:hover {
    transform: scale(1.025); 
    background-color: #38d4da; 
}

.project-card img{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.project-card .text-container{
    flex: 1;
    align-self: start;
    align-items: center;
}

.project-card .text-container p{
    align-self: start;
    align-items: center;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.contact-text {
    flex: 1;
    font-size: 4rem;
    display: flex;
    align-items:center; /* This will center the text vertically */
    justify-content:right; /* This will center the text horizontally */
    text-align: right;
    color: rgb(185, 201, 78);
}

.contact-form {
    flex: 1; /* This will make the form div take up the other half of the width of the container */
}

